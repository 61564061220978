<template>
  <div class="px-5" id="page-subcompany">
    <h5 class="mb-6 ml-2">{{ $t('COMPANY.SUBCOMPANIES') }}</h5>

    <Confirm :title="$t('COMPANY.DELETE_HEADER_CONFIRM')" :message="$t('COMPANY.CONFIRM_DELETE')" ref="confirmModal"
      @cancel="cancel_remove" @confirm="confirm_remove" />

    <b-modal ref="editCompanyModal" size="lg" hide-footer>
      <b-alert v-if="creating" show variant="primary">{{ $t('COMPANY.YOU_ARE_CREATING', { company: parent.text }) }}</b-alert>

      <b-alert v-if="!creating && company" show variant="secondary">{{ $t('COMPANY.YOU_ARE_EDITING', { company: company.text}) }}</b-alert>

      <BasicCompanySettings
        v-if="company"
        :company_id="company.id"
        :company="company"
        :regions="regions"
        @saved="company_saved"
        @change="company_changed"
      />
    </b-modal>

    <b-card title="" class="mb-2" hide-footer oncontextmenu="return false;">

      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('COMPANY.COMPANY_HIERARCHY') }}</v-tab>
        <v-tab>{{ $t('COMMON.SETTINGS') }}</v-tab>

        <v-tab-item>
          <b-row class="mt-8 mb-4">
            <b-col lg="6">
              <b-button :disabled="selectedNode === null" variant="outline-success" class="ml-2" type="button"
                @click="edit_company_clicked">{{ $t('COMPANY.EDIT') }}</b-button>
              <b-button :disabled="selectedNode === null" variant="outline-success" class="ml-2" type="button"
                @click="create_company_clicked">{{ $t('COMPANY.NEW') }}</b-button>
              <b-button :disabled="selectedNode === null || !selectedNode.parent_id" variant="outline-danger"
                class="ml-2" type="button" @click="remove_company_clicked">{{ $t('COMPANY.DELETE') }}</b-button>
            </b-col>
            <b-col lg="6">

              <div class="d-flex align-items-end flex-column">
                <b-button @click="print_letter_labels" variant="outline-primary" class="ml-2">
                  {{ $t('EXPORT.PRINT_LABELS') }}
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <p v-if="selectedNode === null" style="font-style: italic;">{{ $t('COMPANY.SELECT_TO_EDIT') }}</p>
              <p v-else>{{ $t('COMPANY.SELECTED_COMPANY') }}: {{ selectedNode.name }}</p>
            </b-col>
          </b-row>
          <v-treeview class="mt-8" :items="treeData" :treeTypes="treeTypes" :openAll="openAll" activatable item-key="id"
            hoverable :active.sync="active">
            <template v-slot:prepend="{ item, open }">
              <i v-if="item.type === 'riks'" class="fas fa-sitemap"></i>
              <i v-if="item.type === 'default'" class="fas fa-building"></i>
              <i v-if="item.type === 'student'" class="fas fa-book"></i>
              <i v-if="item.type === 'workgroup'" class="fas fa-user"></i>
            </template>


            <template v-slot:label="{ item }">
              <v-hover v-slot:default="{ hover }">
                <div>
                  <span>{{ item.name }}</span>
                  <span class="text-muted"> ({{ item.member_count }})</span>
                </div>
              </v-hover>
            </template>


          </v-treeview>
        </v-tab-item>

        <v-tab-item>

        </v-tab-item>
      </v-tabs>
    </b-card>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BasicCompanySettings from '@/view/pages/ml/settings/BasicCompanySettings.vue';
import Confirm from '@/view/components/Confirm.vue';
import { DELETE_COMPANY } from '@/core/services/store/common.module';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

export default {
  name: 'subcompany',
  components: {
    BasicCompanySettings,
    Confirm
  },
  computed: {

    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.load_data();
  },
  mixins: [ toasts ],
  data() {
    return {
      active: [],
      selection: [],
      openAll: true,
      treeTypes: [
        {
          type: "#",
          max_children: 6,
          max_depth: 4,
          valid_children: [
            "default",
            "riks",
            "student",
            "workgroup"
          ]
        },
        {
          type: "default",
          icon: "fas fa-building",
          valid_children: ["default", "riks", "student", "workgroup"]
        },
        {
          type: "riks",
          icon: "fas fa-sitemap",
          valid_children: ["default", "riks", "student", "workgroup"]
        },
        {
          type: "student",
          icon: "fas fa-book",
          valid_children: ["default", "riks", "student", "workgroup"]
        },
        {
          type: "workgroup",
          icon: "fas fa-user",
          valid_children: ["workgroup"]
        }
      ],
      treeData: [],
      contextItems: [],
      selectedNode: null,
      regions: {},
      creating: false,
      parent: null,
      company: null
    };
  },
  watch: {
    active() {
      this.selectedNode = this.find_node(this.active[0]);

      this.selected(this.selectedNode);
    },
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.load_data();
      }
    },
    currentPeriodId(newValue) {
      if (newValue) {
        this.load_membercounts();
      }
    }
  },
  methods: {
    company_changed(company) {
      this.company = { ...company };
    },

    async print_letter_labels() {
      try {

        const res = await axios.post(`/export/letter_labels/company/${this.currentCompanyId}`, {});

        if (res.status === 201) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.EXPORT_SUCCESS'));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
        }
      }
      catch (err) {
        console.error('print_letter_labels catched error', err);
      }
    },

    find_node(id) {
      return this.get_company_in_tree(this.treeData[0], id);
    },

    async edit_company_clicked() {
      const company_id = this.selectedNode.id;

      if (!company_id) {
        return;
      }

      try {
        const res = await axios.get(`/company/admin?company_id=${company_id}`);

        if (res.status !== 200) {
          return;
        }

        this.company = res.data;

        this.creating = false;
        this.$refs['editCompanyModal'].show();
      }
      catch (err) {
        console.error(err);
      }

    },
    create_company_clicked() {
      const parent_company_id = this.selectedNode.id;
      this.parent = this.get_company_in_tree(this.treeData[0], parent_company_id);

      this.company = {
        pc_id: parent_company_id,
        name: 'Ny förening',
        type: 'default',
        renewable: true,
        mucf: {
          include: false
        },
        stats: {
          pcinc: false
        }
      }

      this.creating = true;

      this.$refs['editCompanyModal'].show();
    },
    reset_selected_item() {
      this.active = []
      this.selectedNode = null;
    },
    remove_company_clicked() {
      this.$refs['confirmModal'].show();
    },
    async confirm_remove() {
      try {
        const res = await axios.delete(`/company/${this.selectedNode.id}`);
        await this.$store.dispatch(DELETE_COMPANY, this.selectedNode.id);

        if (res.data.update_hierarchy) {
          window.location.reload();
        }
        else {
          await this.load_hierarchy();
        }
        this.reset_selected_item();
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort underförening');
      }
    },
    cancel_remove() {},
    get_company_in_tree(parent, company_id) {
      if (parent.id === company_id) {
        return parent;
      }

      for (let i = 0; i < parent.children.length; ++i) {
        const p = this.get_company_in_tree(parent.children[i], company_id);

        if (p !== null && p.id === company_id) {
          return p;
        }
      }

      return null;
    },
    async load_data() {
      if (!this.currentCompanyId) {
        return;
      }
      await this.load_regions();
      await this.load_hierarchy();
      await this.load_membercounts();
    },
    get_lang_type(type) {
      switch (type) {
        case 'default': return 'Lokalförening';
        case 'student': return 'Studentkår';
        case 'riks': return 'Riksförbund';
        case 'workgroup': return 'Arbetsgrupp';
      }

      return 'Inget';
    },
    hierarchy_to_tree_data(tree) {
      this.treeData = [];

      const root = {};
      this.convert_node(root, root, tree);

      this.sort_tree(root);

      this.treeData.push(root);
    },

    sort_tree(node) {
      node.children.sort(function (a, b) {
        if (a.sortNum < b.sortNum) {
          return -1;
        }

        return 1;
      });

      for (const c of node.children) {
        this.sort_tree(c);
      }
    },
    convert_node(parent, treeNode, memlistNode) {
      treeNode.id = memlistNode.company_id;
      treeNode.text = memlistNode.cnum + ': ' + memlistNode.name;
      treeNode.name = memlistNode.cnum + ': ' + memlistNode.name;
      const cnum = parseInt(memlistNode.cnum + '');

      treeNode.sortNum = isNaN(cnum) ? memlistNode.name : cnum;
      treeNode.type = memlistNode.type;
      treeNode.children = [];
      treeNode.count = 0;
      treeNode.parent_id = parent.id === treeNode.id ? undefined : parent.id;

      if (parent !== treeNode) {
        parent.children.push(treeNode);
        parent.count++;
      }

      for (let i = 0; i < memlistNode.children.length; ++i) {
        const newNode = {};

        this.convert_node(treeNode, newNode, memlistNode.children[i]);
      }
    },

    set_membercount(counts, node) {
      node.member_count = counts[node.id] ?? 0;
      // Vue3 compatability for Vue.set
      node = { ...node };

      for (let i = 0; i < node.children.length; ++i) {
        this.set_membercount(counts, node.children[i]);
      }
    },

    async load_membercounts() {
      if (!this.treeData) { return; }
      const loader = this.$loading.show();
      const res = await axios.get(`/company/stats/${this.currentCompanyId}/${this.currentPeriodId}`);
      if (res.status === 200) {
        const counts = {};
        for (const item of res.data) {
          counts[item.company_id] = item.cnt;
        }
        for (let i = 0; i < this.treeData.length; ++i) {
          this.set_membercount(counts, this.treeData[i]);
        }
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta antal medlemmar per förening');
      }
      loader && loader.hide();
    },

    async load_hierarchy() {
      const loader = this.$loading.show();
      const res = await axios.get(`/company/hierarchy/${this.currentCompanyId}`);
      if (res.status == 200) {
        this.hierarchy_to_tree_data(res.data);
      } else {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ladda hierarki');
      };
      loader && loader.hide();
    },

    async load_regions() {
      const loader = this.$loading.show();
      const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes');
      if (res.status == 200)
        this.regions = res.data.regions;
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta regioner');
      }
      loader && loader.hide();
    },
    get_type_rule(type) {
      const typeRule = this.treeTypes.filter(t => t.type == type)[0];
      return typeRule;
    },
    company_saved(company) {
      //location.reload();
      this.$refs['editCompanyModal'].hide();
      this.creating = false;
      this.load_data();
    },

    selected(node) {
      if (!node) {
        return;
      }

      this.selectedNode = node;

      this.contextItems = [];
      const typeRule = this.get_type_rule(this.selectedNode.type);
      typeRule.valid_children.map(function (type, key) {
        const childType = this.get_type_rule(type);
        const item = {
          title: "Skapa " + this.get_lang_type(type),
          icon: childType.icon,
          type: childType
        };
        this.contextItems.push(item);
      }, this);

      this.contextItems.push({ title: "Ändra", icon: "far fa-edit" });
      this.contextItems.push({ title: "Ta bort", icon: "far fa-trash-alt" });
    },

  }
};
</script>
